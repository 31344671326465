// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

@keyframes @spot-pulsar--animation {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.@{spot-pulsar--prefix-cls} {
    inline-size: 46px;
    block-size: 46px;
    position: absolute;
    pointer-events: none;
    inset-block-start: 0;
    inset-inline-start: 0;

    &__ring {
        box-sizing: border-box;
        position: absolute;
        inset-block-start: 50%;
        border: 2px solid #28aade;
        border-radius: 50%;
        inline-size: 100%;
        block-size: 100%;
        margin-block-start: -50%;
        transform: scale(1);
        animation: @spot-pulsar--animation 2s 0s ease-in-out infinite;

        &:nth-child(1) {
            animation-delay: -1s;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;