// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

// Also see z-indexes at constants.js

@double-list-selector--search-z-index: 1;
@button-group--button-z-index: 2;
@card-list--content-blocker-z-index: 10;
@card-list--loader-z-index: 10;
@search-bar--menu-z-index: 1035;
@tooltip--z-index: 1040;
