// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Icon/variables";

@progress-bar--prefix-cls: ~"@{cls-prefix}progress-bar";
@progress-bar--background-color: @token-color-grey-3;
@progress-bar--on-dark-background-color: @token-color-grey-9;

@progress-indicator--prefix-cls: ~"@{cls-prefix}progress-bar__indicator";
@progress-indicator--background-color: @token-color-primary-2;
@progress-indicator--success-background-color: @token-color-success-2;
@progress-indicator--warning-background-color: @token-color-warning-2;
@progress-indicator--danger-background-color: @token-color-danger-2;
@progress-indicator--on-dark-background-color: @token-color-primary-5;
@progress-indicator--on-dark-success-background-color: @token-color-success-5;
@progress-indicator--on-dark-warning-background-color: @token-color-warning-5;
@progress-indicator--on-dark-danger-background-color: @token-color-danger-5;

@progress-indicator--animation-indeterminate-1: ~"@{progress-indicator--prefix-cls}--animation-indeterminate-1";
@progress-indicator--animation-indeterminate-2: ~"@{progress-indicator--prefix-cls}--animation-indeterminate-2";

.@{progress-bar--prefix-cls} {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    block-size: 2px;
    min-inline-size: 100px;
    background-color: @progress-bar--background-color;

    &--on-dark {
        background-color: @progress-bar--on-dark-background-color;
    }

    &__indicator {
        block-size: 100%;
        inline-size: 0;
        transition: inline-size 0.6s ease;
        background-color: @progress-indicator--background-color;

        &--info {
            background-color: @progress-indicator--background-color;
        }

        &--success {
            background-color: @progress-indicator--success-background-color;
        }

        &--warning {
            background-color: @progress-indicator--warning-background-color;
        }

        &--danger {
            background-color: @progress-indicator--danger-background-color;
        }

        .@{progress-bar--prefix-cls}--on-dark &,
        .@{progress-bar--prefix-cls}--on-dark &--info {
            background-color: @progress-indicator--on-dark-background-color;
        }

        .@{progress-bar--prefix-cls}--on-dark &--success {
            background-color: @progress-indicator--on-dark-success-background-color;
        }

        .@{progress-bar--prefix-cls}--on-dark &--warning {
            background-color: @progress-indicator--on-dark-warning-background-color;
        }

        .@{progress-bar--prefix-cls}--on-dark &--danger {
            background-color: @progress-indicator--on-dark-danger-background-color;
        }
    }

    &__indicator--indeterminate {
        &::before,
        &::after {
            position: absolute;
            inset-block-start: 0;
            inset-block-end: 0;
            inset-inline-start: 0;
            content: "";
            background-color: inherit;
            will-change: left, right;
        }

        &::before {
            animation: @progress-indicator--animation-indeterminate-1 2.1s
                cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }

        &::after {
            animation: @progress-indicator--animation-indeterminate-2 2.1s
                cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.15s;
        }
    }
}

@keyframes @progress-indicator--animation-indeterminate-1 {
    0% {
        inset-inline-start: -35%;
        inset-inline-end: 100%;
    }

    100%,
    60% {
        inset-inline-start: 100%;
        inset-inline-end: -90%;
    }
}

@keyframes @progress-indicator--animation-indeterminate-2 {
    0% {
        inset-inline-start: -200%;
        inset-inline-end: 100%;
    }

    100%,
    60% {
        inset-inline-start: 107%;
        inset-inline-end: -8%;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;