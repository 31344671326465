// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Overlay/variables";
@import "../Drawer/variables";
@import "../Button/variables";
@import "../Heading/variables";

.@{drawer-header--prefix-cls} {
    flex: none;
    padding-inline: 24px;
    z-index: 10;

    &__inner {
        display: flex;
        align-items: flex-start;
        padding-block: 20px 16px;
        border-block-end: 1px solid #d3d3de;
    }

    .@{drawer--prefix-cls}__title {
        margin-block-end: 0;
        font-size: @heading--h2-font-size;
        font-weight: 400;
        line-height: @heading--h2-line-height;

        > :last-child {
            margin-block-end: 0;
        }
    }

    .@{drawer--prefix-cls}__title + .@{drawer--prefix-cls}__subtitle {
        margin-block-start: 12px;
    }

    &__content {
        flex-grow: 1;
        align-self: center;
    }

    &__actions {
        display: flex;
        align-items: center;
        min-block-size: 40px;
        margin: logical -4px 0 -4px -8px;
    }

    &__button {
        border-radius: 8px;
        background-color: var(--dialogHeaderButtonBackground, #fff);
    }

    &__actions > &__button,
    &__actions > &__button[disabled] {
        &,
        &:hover,
        &:focus,
        &:active {
            border-radius: 8px;
            background-color: var(--dialogHeaderButtonBackground, #fff);
            box-shadow: var(--dialogHeaderButtonBorder, none);
        }
    }

    &__actions > &__button:hover,
    &__actions > &__button:focus {
        background-color: var(--dialogHeaderButtonBackgroundHover, #f4f4f4);
    }

    &__actions > &__button:focus {
        box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.24);
    }

    &__heading &__button:hover,
    &__heading &__button:focus,
    &__heading &__button:active {
        background-color: rgba(0, 0, 0, 0.05);
        .@{button--prefix-cls}__icon {
            color: rgba(255, 255, 255, 0.9);
        }
    }

    &__heading &__button:active {
        background-color: rgba(0, 0, 0, 0.09);
    }

    &__actions > &__button {
        margin-inline-start: 8px;
    }

    &__button--back {
        margin: logical -4px -8px -4px 8px;
    }

    .@{overlay--prefix-cls}--right & .@{button--prefix-cls}--ghost .@{button--prefix-cls}__icon {
        transform: scale(-1);
    }

    @media (min-width: 850px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--xs &__actions {
            margin-inline-end: calc(-8px - var(--sideBannerWidth, 340px));

            --dialogHeaderButtonBackgroundHover: #efefef;
            --dialogHeaderButtonBorder: inset 0 0 0 1px rgba(29, 72, 112, 0.1);
        }
    }

    @media (min-width: 1000px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--sm &__actions {
            margin-inline-end: calc(-8px - var(--sideBannerWidth, 340px));

            --dialogHeaderButtonBackgroundHover: #efefef;
            --dialogHeaderButtonBorder: inset 0 0 0 1px rgba(29, 72, 112, 0.1);
        }
    }

    @media (min-width: 1240px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--md &__actions {
            margin-inline-end: calc(-8px - var(--sideBannerWidth, 340px));

            --dialogHeaderButtonBackgroundHover: #efefef;
            --dialogHeaderButtonBorder: inset 0 0 0 1px rgba(29, 72, 112, 0.1);
        }
    }

    @media (min-width: 15400px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--lg &__actions {
            margin-inline-end: calc(-8px - var(--sideBannerWidth, 340px));

            --dialogHeaderButtonBackgroundHover: #efefef;
            --dialogHeaderButtonBorder: inset 0 0 0 1px rgba(29, 72, 112, 0.1);
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;