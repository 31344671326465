// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

/*
Plaintext

Markup:
<pre class="plaintext">{{children}}</pre>
*/

@plaintext--prefix-cls: ~"@{cls-prefix}plaintext";

.@{plaintext--prefix-cls} {
    pre& {
        background: transparent;
        margin: 0;
        padding: 0;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        word-wrap: break-word;
        line-height: inherit;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;