// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@switches-panel--prefix-cls: ~"@{cls-prefix}switches-panel";

.@{switches-panel--prefix-cls} {
    &__items {
        display: flex;
        flex-flow: row wrap;
        margin-block: -16px 0;
        margin-inline: -16px 0;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__item {
        margin-block: 16px 0;
        margin-inline: 16px 0;
        flex: 0 0 auto;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;