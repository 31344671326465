// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../FormField/variables";

.@{textarea--prefix-cls} {
    box-sizing: border-box;
    max-inline-size: 100%;
    padding-block: 4px;
    padding-inline: 8px;
    border: 1px solid @textarea--border-color;
    border-radius: 2px;
    background-color: @textarea--background-color;
    color: @textarea--color;
    box-shadow: @textarea--shadow;

    &:hover {
        border-color: @textarea--border-color-hover;
    }

    &:focus {
        border-color: @textarea--border-color-focus;
        background-color: @textarea--background-color-focus;
        color: @textarea--color-focus;
        box-shadow: @textarea--shadow-focus;
        outline: 0;
    }

    &[readonly] {
        border-color: @textarea--border-color;
        background-color: @textarea--background-color;
        color: @textarea--color;
    }

    &[disabled] {
        border-color: @textarea--border-color-disabled;
        background-color: @textarea--background-color-disabled;
        color: @textarea--color-disabled;
        opacity: 1;
    }

    .@{form-field--prefix-cls}--error & {
        &,
        &:hover,
        &:focus {
            border-color: @textarea--border-color-error;
            background-color: @textarea--background-color-error;
            box-shadow: @textarea--shadow;
        }
    }

    &--size-sm {
        inline-size: @textarea--size-sm;
    }

    &--size-md {
        inline-size: @textarea--size-md;
    }

    &--size-lg {
        inline-size: @textarea--size-lg;
    }

    &--size-xl {
        inline-size: @textarea--size-xl;
    }

    &--size-fill {
        inline-size: @textarea--size-fill;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;