// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "variables";
@import "../Button/variables";

.@{extended-status-message--prefix-cls} {
    display: flex;
    max-inline-size: 550px;
    margin-block: 16px;
    margin-inline: auto;
    padding-block: 20px;
    padding-inline: 24px;
    border-radius: 2px;
    background-color: @extended-status-message--background;

    hr {
        border-block-start-color: @base-hr-border;
    }

    &--loading {
        position: relative;
        min-block-size: 148px;
    }
}

.@{extended-status-message--prefix-cls}--success {
    background-color: @extended-status-message--success-background;
    hr {
        border-block-start-color: darken(@extended-status-message--success-background, 20%);
    }
}

.@{extended-status-message--prefix-cls}--info {
    background-color: @extended-status-message--info-background;
    hr {
        border-block-start-color: darken(@extended-status-message--info-background, 20%);
    }
}

.@{extended-status-message--prefix-cls}--warning {
    background-color: @extended-status-message--warning-background;
    hr {
        border-block-start-color: darken(@extended-status-message--warning-background, 20%);
    }
}

.@{extended-status-message--prefix-cls}--danger {
    background-color: @extended-status-message--danger-background;
    hr {
        border-block-start-color: darken(@extended-status-message--danger-background, 20%);
    }
}

.@{extended-status-message--prefix-cls}__image {
    padding-inline-end: 24px;

    img {
        max-inline-size: 128px;
    }
}

.@{extended-status-message--prefix-cls}__body {
    flex: 1 1 auto;
}

.@{extended-status-message--prefix-cls}__description {
    display: block;
    margin-block-start: 8px;
}

.@{extended-status-message--prefix-cls}__actions {
    margin-block-start: 8px;

    .@{button--prefix-cls} {
        margin-block: 8px 0;
        margin-inline: 0 8px;
    }
}

@media (max-width: 600px) {
    .@{extended-status-message--prefix-cls} {
        flex-direction: column;
        text-align: center;
    }

    .@{extended-status-message--prefix-cls}__image {
        padding-block: 0 16px;
        padding-inline: 0;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;