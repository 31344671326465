// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{spinner--prefix-cls} {
    inline-size: 16px;
    block-size: 16px;
    animation: @spinner--animation-rotate @spinner--animation-duration linear infinite;

    &__path {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: 0px; /* stylelint-disable-line length-zero-no-unit */
        transform-origin: 50% 50%;
        stroke: #4285f4;
        animation: @spinner--animation-dash @spinner--animation-duration ease-in-out infinite,
            @spinner--animation-colors (@spinner--animation-duration * 4) linear infinite;
    }
}

@keyframes @spinner--animation-rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes @spinner--animation-dash {
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0px; /* stylelint-disable-line length-zero-no-unit */
    }

    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }

    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -120px;
    }
}

@keyframes @spinner--animation-colors {
    from {
        stroke: #4285f4;
    }
    25% {
        stroke: #de3e35;
    }
    50% {
        stroke: #f7c223;
    }
    75% {
        stroke: #1b9a59;
    }
    to {
        stroke: #4285f4;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;