// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

/*
Subnav

Markup:
<div class="subnav">
    <a class="subnav__item {{.modifier}}" href="...">...</a>
</div>

Modifiers:
.subnav__item--active
*/

@subnav--prefix-cls: ~"@{cls-prefix}subnav";

@subnav--color: @base-link-color;
@subnav--color-hover: @base-link-hover-color;
@subnav--color-active: @base-link-active-color;
@subnav--background-color: transparent;
@subnav--background-color-hover: rgba(34, 34, 34, 0.05);
@subnav--background-color-active: rgba(34, 34, 34, 0.09);
@subnav--selected-color: @base-body-color;
@subnav--selected-background-color: @subnav--background-color-active;
@subnav--focus-shadow: 0 0 0 4px rgba(0, 149, 255, 0.2);

.@{subnav--prefix-cls} {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin-block: 0 15px;
    margin-inline: -1px 0;

    &__item {
        position: relative;
        flex: 1 1 40%;
        margin-block: 0 1px;
        margin-inline: 1px 0;
        padding: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 2px;
        background-color: @subnav--background-color;
        font: inherit;
        color: @subnav--color;
        text-transform: none;
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        min-block-size: 28px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 600;
        text-decoration: none;
        text-align: center;
        white-space: nowrap;
        cursor: pointer;
        -webkit-appearance: none;

        &:hover {
            color: @subnav--color-hover;
            background-color: @subnav--background-color-hover;
            text-decoration: none;
        }

        &:active {
            color: @subnav--color-active;
            background-color: @subnav--background-color-active;
        }

        &:focus {
            outline: none;
            box-shadow: @subnav--focus-shadow;
        }

        &--active,
        &--active:hover,
        &--active:active,
        &--active:focus {
            color: @subnav--selected-color;
            background-color: @subnav--selected-background-color;
        }
    }
}

@media (min-width: @screen-sm) {
    .@{subnav--prefix-cls} {
        flex-flow: nowrap;
        margin-block: 0 16px;
        margin-inline: 0;

        &__item {
            flex: 0 1 180px;
            inline-size: auto;
            text-align: center;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;