// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../FormField/variables";
@import "../Checkbox/variables";

.@{form-field-checkbox--prefix-cls} {
    .@{form-field--prefix-cls}__error,
    .@{form-field--prefix-cls}__description {
        margin-inline-start: 28px;
    }

    .@{checkbox--prefix-cls} {
        padding-inline-start: 28px;

        &__text {
            display: inline;
            margin-inline-start: 0;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;