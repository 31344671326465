// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../../helpers/mixins";
@import "../Icon/variables";
@import "../Button/variables";
@import "../Toaster/variables";

/*
Toast

Markup:
<div class="toaster toaster--top">
    <div class="toast toast--success" tabindex="0">
        <span class="toast__icon"><i className="icon-ok"></i></span>
        <span class="toast__content">toast message</span>
        <span class="toast__action"><span class="close"></span></span>
    </div>
</div>

Modifiers:
.toaster--top

.toast--success
.toast--warning
.toast--danger
.toast--info

.toast--enter
.toast--enter-active
.toast--leave
.toast--leave-active
*/

@toast--prefix-cls: ~"@{cls-prefix}toast";
@toast--success-color: @text-on-dark-success-color;
@toast--info-color: @text-on-dark-info-color;
@toast--warning-color: @text-on-dark-warning-color;
@toast--danger-color: @text-on-dark-danger-color;
@toast--inactive-color: @text-on-dark-muted-color;

.@{toast--prefix-cls} {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-block: 8px 0;
    margin-inline: 0;
    padding-block: 12px;
    padding-inline: 16px;
    border-radius: 2px;
    color: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(34, 34, 34, 0.33);
    background-color: #1f2023;
    max-inline-size: 400px;
    inline-size: 100%;
    pointer-events: all;

    &:focus {
        outline: none;
    }

    &--info &__icon {
        color: @toast--info-color;
    }

    &--success &__icon {
        color: @toast--success-color;
    }

    &--warning &__icon {
        color: @toast--warning-color;
    }

    &--danger &__icon {
        color: @toast--danger-color;
    }

    &__icon,
    &__action {
        flex: 0 0 auto;
    }

    &__action {
        margin-inline-end: -4px;
        padding-inline-start: 8px;
    }

    &__icon {
        line-height: 1;
        padding-block: 2px;
        padding-inline: 0 6px;
    }

    &__content {
        flex: 1 1 auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
        overflow: hidden;
    }

    &--enter,
    &--enter ~ & {
        transform: translateY(-40px);
    }

    &--enter-active,
    &--enter-active ~ & {
        transform: translateY(0);
        transition: transform 0.3s cubic-bezier(0.54, 1.12, 0.38, 1.11);
    }

    &--exit {
        opacity: 1;
        filter: blur(0);
    }

    &--exit-active {
        opacity: 0;
        filter: blur(10px);
        transition: opacity 0.3s cubic-bezier(0.4, 1, 0.75, 0.9),
            filter 0.3s cubic-bezier(0.4, 1, 0.75, 0.9);
    }

    &--exit ~ & {
        transform: translateY(0);
    }

    &--exit-active ~ & {
        transform: translateY(-40px);
        transition: transform 0.1s cubic-bezier(0.4, 1, 0.75, 0.9) 0.05s;
    }

    .@{toaster--prefix-cls}--bottom-end & {
        &--enter,
        &--enter ~ .@{toast--prefix-cls} {
            transform: translateY(40px);
        }

        &--enter-active,
        &--enter-active ~ .@{toast--prefix-cls} {
            transform: translateY(0);
        }

        &--exit ~ .@{toast--prefix-cls} {
            transform: translateY(0);
        }

        &--exit-active ~ .@{toast--prefix-cls} {
            transform: none;
        }
    }

    .@{button--prefix-cls}&__close {
        vertical-align: top;
        inline-size: 20px;
        block-size: 20px;
        min-inline-size: 20px;
        min-block-size: 20px;
        padding: 4px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;