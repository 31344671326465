// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "variables";

.@{item--prefix-cls} {
    display: block;
    font-size: @item--font-size;
    line-height: @item--line-height;
    color: @item--color;
    transition: box-shadow linear 0.25s;

    a&,
    a&:hover,
    a&:active {
        color: @item--color;
        text-decoration: none;
    }

    &:focus:not(&--disabled) {
        box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.24);
        outline: none;
    }

    &__description {
        font-size: @item--font-size-small;
        line-height: @item--line-height-small;
        color: @item--description-color;
    }

    &__description + &__content {
        margin-block-start: 8px;
    }

    &__content > :last-child {
        margin-block-end: 0;
    }

    &:not(&--card) &__content {
        font-size: @item--font-size-small;
        line-height: @item--line-height-small;
    }

    &__footer {
        margin-block: 12px -16px;
        margin-inline: 0;
        padding-block: 8px;
        padding-inline: 0;
        border-block-start: 1px solid @item--footer-border-color;

        > :last-child {
            margin-block-end: 0;
        }
    }

    &--card {
        padding-block: 16px;
        padding-inline: 20px;
        background-color: @item--card-background-color;
        box-shadow: @item--card-shadow;
    }

    &--disabled {
        &,
        &:hover,
        &:focus,
        &:active {
            opacity: 0.47;
            cursor: not-allowed;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;