// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../../helpers/mixins";
@import "variables";

/*
Layout

Type modifiers:
.layout--fluid
.layout--simplified

State modifiers:
.layout--touch
.layout--header-fixed
.layout--sidebar-collapsed

Size modifiers:
.layout--sm
.layout--md
.layout--lg

Sidebar modifiers:
.layout__sidebar--on
.layout__sidebar--off
.layout__sidebar--enter
.layout__sidebar--exit
*/

.@{layout--prefix-cls} {
    display: flex;
    box-sizing: border-box;

    &--simplified {
        min-block-size: 100vh;
        padding-block: 20px;
        padding-inline: 0;
        background-color: @layout--simplified-background-color;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        inline-size: 100%;
        min-block-size: 100vh;
        box-sizing: border-box;
    }

    &--simplified &__inner {
        inline-size: 100%;
        max-inline-size: 640px;
        min-block-size: auto;
        margin-block: 0;
        margin-inline: auto;
        align-self: flex-start;
        border-start-start-radius: 6px;
        border-start-end-radius: 6px;
        border-end-start-radius: 4px;
        border-end-end-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.17);
    }

    &__header {
        position: relative;
    }

    &__header-inner {
        color: @layout--header-color;
        background-color: @layout--header-background-color;
        box-shadow: @layout--header-shadow;
    }

    &--simplified &__header-inner {
        min-block-size: 48px;
        color: @layout--simplified-header-color;
        border-start-start-radius: 4px;
        border-start-end-radius: 4px;
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        background-color: @layout--simplified-header-background-color;
        box-shadow: none;
    }

    &__header-addon > :last-child {
        margin-block-end: 0;
    }

    &__header-content {
        display: flex;
    }

    &__header-content-inner {
        flex-grow: 1;
        align-self: center;
    }

    &__responsive-sidebar-toggler {
        flex: none;
        display: inline-block;
        inline-size: 48px;
        min-block-size: 48px;
        margin: 0;
        padding: 16px;
        box-sizing: border-box;
        font: inherit;
        color: inherit;
        text-transform: none;
        border: 0;
        background-color: @layout--sidebar-background-color;
        cursor: pointer;
        overflow: visible;
        outline: none;
        user-select: none;

        @media (min-width: @screen-sm) {
            display: none;
        }
    }

    &__responsive-sidebar-toggler-inner {
        display: block;
        position: relative;
        inline-size: 16px;
        block-size: 16px;
    }

    &__responsive-sidebar-toggler-icon,
    &__responsive-sidebar-toggler-inner::before,
    &__responsive-sidebar-toggler-inner::after {
        display: block;
        position: absolute;
        block-size: 2px;
        inline-size: 16px;
        background: #fff;
        border-radius: 2px;
        opacity: 0.73;
        inset-inline-start: 0;
        transform: rotate(0deg);
        transform-origin: 50% 50%;
        transition: 0.25s ease-in-out;
    }

    &__responsive-sidebar-toggler-icon {
        inset-block-start: 7px;
    }

    &__responsive-sidebar-toggler-inner::before {
        content: "";
        inset-block-start: 2px;
    }

    &__responsive-sidebar-toggler-inner::after {
        content: "";
        inset-block-start: 12px;
    }

    &__responsive-sidebar-toggler--open &__responsive-sidebar-toggler-inner {
        &::before {
            inset-block-start: 7px;
            transform: rotate(45deg);
        }

        &::after {
            inset-block-start: 7px;
            transform: rotate(-45deg);
        }
    }

    &__responsive-sidebar-toggler--open &__responsive-sidebar-toggler-icon {
        opacity: 0;
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &__sidebar {
        position: relative;
        flex: none;
    }

    &__sidebar-inner {
        color: @layout--sidebar-color;
        background-color: @layout--sidebar-background-color;
    }

    &__sidebar-content {
        overflow: auto;
    }

    &__sidebar-toggler {
        display: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-inline-size: 0;
        background-color: @layout--content-background-color;
    }

    &--simplified &__content {
        border-start-start-radius: 0;
        border-start-end-radius: 0;
        border-end-start-radius: 4px;
        border-end-end-radius: 4px;
    }

    &__content-addon {
        flex: none;
        background-color: @layout--content-background-color;
    }

    &__main {
        flex: 1 1 auto;
        inline-size: 100%;
    }

    &__main-inner {
        padding-block: 16px 24px;
        padding-inline: 16px;
        inline-size: 100%;
        margin: auto;
        box-sizing: border-box;

        @media (min-width: @screen-sm) {
            padding-inline-end: 24px;
            padding-inline-start: 24px;
        }

        .@{layout--prefix-cls}--sm & {
            max-inline-size: 640px;
        }
        .@{layout--prefix-cls}--md & {
            max-inline-size: 960px;
        }
        .@{layout--prefix-cls}--lg & {
            max-inline-size: 1200px;
        }
    }

    &--simplified &__main {
        flex: none;
    }

    &--content-boxed &__main {
        inline-size: 100%;
        margin: auto;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        min-block-size: 36px;
        color: @layout--footer-color;
        background-color: @layout--footer-background-color;
        border-block-start: 1px solid @layout--footer-border-color;
    }

    &--simplified &__footer {
        border-start-start-radius: 0;
        border-start-end-radius: 0;
        border-end-start-radius: 4px;
        border-end-end-radius: 4px;
    }

    &--sm {
        &.@{layout--prefix-cls}--boxed .@{layout--prefix-cls}__inner,
        &.@{layout--prefix-cls}--boxed.@{layout--prefix-cls}--header-fixed
            .@{layout--prefix-cls}__header-inner,
        &.@{layout--prefix-cls}--simplified .@{layout--prefix-cls}__inner,
        &.@{layout--prefix-cls}--mixed .@{layout--prefix-cls}__container {
            max-inline-size: 640px;
        }
    }

    &--md {
        &.@{layout--prefix-cls}--boxed .@{layout--prefix-cls}__inner,
        &.@{layout--prefix-cls}--boxed.@{layout--prefix-cls}--header-fixed
            .@{layout--prefix-cls}__header-inner,
        &.@{layout--prefix-cls}--simplified .@{layout--prefix-cls}__inner,
        &.@{layout--prefix-cls}--mixed .@{layout--prefix-cls}__container {
            max-inline-size: 960px;
        }
    }

    &--lg {
        &.@{layout--prefix-cls}--boxed .@{layout--prefix-cls}__inner,
        &.@{layout--prefix-cls}--boxed.@{layout--prefix-cls}--header-fixed
            .@{layout--prefix-cls}__header-inner,
        &.@{layout--prefix-cls}--simplified .@{layout--prefix-cls}__inner,
        &.@{layout--prefix-cls}--mixed .@{layout--prefix-cls}__container {
            max-inline-size: 1200px;
        }
    }

    @media (max-width: @screen-xs-max) {
        &--sidebar-collapsed &__sidebar,
        &--sidebar-folded &__sidebar {
            display: none;
        }
    }

    @media (min-width: @screen-sm) {
        &__header,
        &__header-inner,
        &__header-content {
            min-block-size: 48px;
        }

        &--header-fixed &__header-inner {
            position: fixed;
            inset-block-start: 0;
            inset-inline-end: 0;
            inset-inline-start: 0;
            z-index: 1030;
            min-block-size: 48px;
        }

        &__container {
            flex-direction: row;
        }

        &__sidebar {
            inline-size: 224px;
        }

        &--sidebar-collapsed &__sidebar {
            inline-size: 0;
        }

        &--sidebar-folded &__sidebar {
            inline-size: 48px;
        }

        &--sidebar&--header-fixed &__header-inner {
            inset-inline-start: 224px;
        }

        &--sidebar-collapsed&--header-fixed &__header-inner {
            inset-inline-start: 0;
        }

        &--sidebar-folded&--header-fixed &__header-inner {
            inset-inline-start: 48px;
        }

        &__sidebar-inner {
            position: fixed;
            inset-block-start: 0;
            inset-inline-start: 0;
            inset-block-end: 0;
            inline-size: 224px;
            z-index: 1032;
        }

        &--sidebar-collapsed &__sidebar-inner {
            inset-inline-start: -224px;
        }

        &--sidebar-folded &__sidebar-inner {
            inline-size: 48px;
        }

        &__sidebar-toggler {
            display: block;
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: -16px;
            inset-block-end: 0;
            inline-size: 16px;
            border: none;
            margin: 0;
            padding: 0;
            appearance: none;
            background-color: transparent;

            &:hover .@{layout--prefix-cls}__sidebar-toggler-inner,
            &:focus .@{layout--prefix-cls}__sidebar-toggler-inner {
                inset-block-start: 0;
                block-size: 100%;
                margin: 0;
                border-radius: 0;
            }

            &:focus {
                outline: none;
            }
        }

        &__sidebar-toggler-inner {
            position: absolute;
            inset-block-start: 50%;
            inset-inline-start: 0;
            inline-size: 16px;
            block-size: 88px;
            margin-block-start: -44px;
            border-start-start-radius: 0;
            border-start-end-radius: 4px;
            border-end-start-radius: 0;
            border-end-end-radius: 4px;
            background-color: @token-color-grey-a3;
            transition: 0.25s 0.15s linear;
            cursor: pointer;

            &::before {
                inset-block-start: 50%;
                inset-inline-start: 0;
                content: "";
                position: absolute;
                display: inline-block;
                inline-size: 8px;
                block-size: 8px;
                border: none;
                margin-block: -4px 0;
                margin-inline: 3px;
                .svg-arrow-8-left("#222");

                background-repeat: no-repeat;
                background-size: contain;
                transform: rotate(0) /*rtl:rotate(180deg)*/;
                transition: 0.25s 0.5s linear;
            }
        }

        &__sidebar--off &__sidebar-toggler-inner::before {
            transform: rotate(180deg) /*rtl:rotate(0)*/;
        }

        &__sidebar-content {
            block-size: 100%;
            overflow: hidden;

            &::-webkit-scrollbar {
                inline-size: 6px;
                background-color: @layout--sidebar-scrollbar;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: @layout--sidebar-scrollbar-thumb;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: @layout--sidebar-scrollbar-thumb-hover;
            }
        }

        &--touch &__sidebar-content,
        &__sidebar-content:hover {
            overflow-y: auto;
        }

        &__sidebar-content-inner {
            inline-size: 224px;
        }

        &__sidebar--enter,
        &__sidebar--exit {
            transition: inline-size 0.3s ease-in-out;

            .@{layout--prefix-cls}__sidebar-inner {
                transition: inset-inline-start 0.3s ease-in-out, inline-size 0.3s ease-in-out;
            }
        }

        &__header--enter,
        &__header--exit {
            .@{layout--prefix-cls}__header-inner {
                transition: inset-inline-start 0.3s ease-in-out, inline-size 0.3s ease-in-out;
            }
        }
    }

    &__content-header-inner > :last-child {
        margin-block-end: 0;
    }

    @media (min-width: @screen-sm) and (max-width: 1022px) {
        &--sidebar-type-collapsed &__sidebar {
            inline-size: 0;
        }

        &--sidebar-type-folded &__sidebar {
            inline-size: 48px;
        }

        &--sidebar-type-collapsed&--header-fixed &__header-inner {
            inset-inline-start: 0;
        }

        &--sidebar-type-folded&--header-fixed &__header-inner {
            inset-inline-start: 48px;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;