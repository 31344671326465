// FONT
@token-font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
@token-font-family-monospace: "Consolas", "Liberation Mono", "Menlo", "Courier", monospace;

@token-font-wight-light: 300;
@token-font-wight-regular: 400;
@token-font-wight-semibold: 600;
@token-font-wight-bold: 700;

@token-font-size-1: 12px;
@token-font-size-2: 14px;
@token-font-size-3: 16px;
@token-font-size-4: 20px;
@token-font-size-5: 24px;
@token-font-size-6: 32px;

@token-line-height-1: 1.25; // 16/20, 32/40
@token-line-height-2: 1.3; // 20/26
@token-line-height-3: 1.333; // 12/16, 24/32
@token-line-height-4: 1.429; // 14/20

// COLORS
@token-color-grey-0: #fff;
@token-color-grey-1: #fbfbfb;
@token-color-grey-2: #f5f5f5;
@token-color-grey-3: #ececec;
@token-color-grey-4: #dedede;
@token-color-grey-5: #ccc;
@token-color-grey-6: #b5b5b5;
@token-color-grey-7: #979797;
@token-color-grey-8: #737373;
@token-color-grey-9: #4b4b4b;
@token-color-grey-10: #222;

@token-color-grey-a0: fade(@token-color-grey-10, 0);
@token-color-grey-a1: fade(@token-color-grey-10, 2%);
@token-color-grey-a2: fade(@token-color-grey-10, 5%);
@token-color-grey-a3: fade(@token-color-grey-10, 9%);
@token-color-grey-a4: fade(@token-color-grey-10, 15%);
@token-color-grey-a5: fade(@token-color-grey-10, 23%);
@token-color-grey-a6: fade(@token-color-grey-10, 33%);
@token-color-grey-a7: fade(@token-color-grey-10, 47%);
@token-color-grey-a8: fade(@token-color-grey-10, 63%);
@token-color-grey-a9: fade(@token-color-grey-10, 81%);
@token-color-grey-a10: fade(@token-color-grey-10, 100%);

@token-color-dark-1: #536a7d;
@token-color-dark-2: #384859;
@token-color-dark-3: #2d3a47;

@token-color-primary-0: #f0faff;
@token-color-primary-1: #d6f3ff;
@token-color-primary-2: #28aade;
@token-color-primary-3: #259ccc;
@token-color-primary-4: #2089b2;
@token-color-primary-5: #4dccff;
@token-color-primary-6: #45b8e5;
@token-color-primary-7: #3da3cc;

@token-color-danger-1: #ffdee0;
@token-color-danger-2: #d02d4b;
@token-color-danger-3: #b30122;
@token-color-danger-4: #a80022;
@token-color-danger-5: #ff4d6d;
@token-color-danger-6: #e54562;
@token-color-danger-7: #cc3d57;

@token-color-warning-1: #ffeed4;
@token-color-warning-2: #d66b0d;
@token-color-warning-3: #bd5008;
@token-color-warning-4: #ab4400;
@token-color-warning-5: #ffa04d;
@token-color-warning-6: #e59045;
@token-color-warning-7: #cc803d;

@token-color-success-1: #ddf5ce;
@token-color-success-2: #80af26;
@token-color-success-3: #577d02;
@token-color-success-4: #4b6c00;
@token-color-success-5: #aee545;
@token-color-success-6: #9bcc3d;
@token-color-success-7: #88b236;

// SPACING
@token-spacing-0: 0;
@token-spacing-1: 1px;
@token-spacing-2: 2px;
@token-spacing-3: 4px;
@token-spacing-4: 8px;
@token-spacing-5: 12px;
@token-spacing-6: 16px;
@token-spacing-7: 20px;
@token-spacing-8: 24px;
@token-spacing-9: 28px;
@token-spacing-10: 32px;
@token-spacing-11: 36px;
@token-spacing-12: 40px;
@token-spacing-13: 44px;
@token-spacing-14: 48px;

// SIZING
@token-size-0: 0;

// SHADOWS
@token-shadow-1: 0 1px 1px fade(@token-color-grey-10, 47%);
@token-shadow-2: 0 1px 2px fade(@token-color-grey-10, 15%);
@token-shadow-3: 0 2px 2px fade(@token-color-grey-10, 23%);
@token-shadow-4: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 0 rgba(60, 64, 67, 0.16);
@token-shadow-5: 0 4px 8px fade(#000, 17%);
@token-shadow-6: inset 0 2px 1px fade(@token-color-grey-10, 15%);
@token-shadow-7: inset 0 2px 1px fade(@token-color-grey-10, 23%);
@token-shadow-8: inset 0 2px 1px fade(@token-color-grey-10, 9%);
@token-shadow-9: 0 2px 8px rgba(37, 156, 204, 0.1), 0 1px 3px rgba(26, 111, 145, 0.2);
@token-shadow-10: inset 0 1px 1px 0 rgba(60, 64, 67, 0.08), inset 0 1px 3px 0 rgba(60, 64, 67, 0.16);

@token-shadow-primary-1: 0 1px 1px fade(@token-color-primary-2, 33%);
@token-shadow-primary-2: 0 2px 2px fade(@token-color-primary-3, 33%);
@token-shadow-primary-3: 0 4px 8px fade(@token-color-primary-2, 33%);

@token-shadow-danger-1: 0 1px 1px fade(@token-color-danger-2, 33%);
@token-shadow-danger-2: 0 2px 2px fade(@token-color-danger-3, 33%);
@token-shadow-danger-3: 0 4px 8px fade(@token-color-danger-2, 33%);

@token-shadow-warning-1: 0 1px 1px fade(@token-color-warning-2, 33%);
@token-shadow-warning-2: 0 2px 2px fade(@token-color-warning-3, 33%);
@token-shadow-warning-3: 0 4px 8px fade(@token-color-warning-2, 33%);

@token-shadow-success-1: 0 1px 1px fade(@token-color-success-2, 33%);
@token-shadow-success-2: 0 2px 2px fade(@token-color-success-3, 33%);
@token-shadow-success-3: 0 4px 8px fade(@token-color-success-2, 33%);
