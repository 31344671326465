// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";
@import "../Button/variables.less";

@drawer--prefix-cls: ~"@{cls-prefix}drawer-progress";

.@{drawer--prefix-cls} {
    &__title {
        font-size: 18px;
        line-height: 18px;
        margin-block-end: 16px;
    }

    &__progress {
        margin-block-end: 24px;
    }

    &__footer {
        .@{button--prefix-cls} {
            min-inline-size: 90px;
            margin-inline-end: 10px;
            padding-block: 8px;
            padding-inline: 12px;
            font-weight: 600;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;