// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{dropdown--prefix-cls} {
    position: relative;

    &__overlay {
        position: fixed;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        opacity: 1;
        background-color: rgba(34, 34, 34, 0.7);
        overflow: hidden;
        user-select: none;
        animation: @dropdown--fade-in-animation 0.3s linear;
    }

    &__overlay &__overlay-menu {
        position: absolute;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        max-block-size: ~"calc(100% - 52px)";
        animation: @dropdown--fade-in-up-animation 0.3s linear;
    }

    &__overlay &__menu {
        margin: 0;
        padding: 0;
    }

    &__overlay &__overlay-menu-divider {
        margin: 0;
        padding: 0;
    }

    &__popper {
        max-inline-size: ~"calc(100% - 10px)";
    }
}

@keyframes @dropdown--fade-in-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes @dropdown--fade-in-up-animation {
    0% {
        opacity: 0;
        transform: translateY(156px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;