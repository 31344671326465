// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "variables";

.@{columns--prefix-cls} {
    display: flex;

    &--vertical {
        flex-direction: column;
    }

    &__column {
        box-sizing: border-box;
        word-wrap: break-word;
        overflow-wrap: break-word;
        min-inline-size: 0;

        &--fill {
            flex: auto;
        }

        &--ratio-1 {
            flex: 1;
        }

        &--ratio-2 {
            flex: 2;
        }

        &--ratio-3 {
            flex: 3;
        }

        &--has-width {
            flex: none;
        }
    }

    &__column-inner {
        block-size: 100%;
    }

    &--gap-xxs {
        &:not(.@{columns--prefix-cls}--vertical) {
            margin-inline-start: -@column--gap-xxs;
        }
        &:not(.@{columns--prefix-cls}--vertical) > .@{column--prefix-cls} {
            padding-inline-start: @column--gap-xxs;
        }
        &.@{columns--prefix-cls}--vertical > .@{column--prefix-cls}:not(:first-child) {
            padding-block-start: @column--gap-xxs;
        }
    }

    &--gap-xs {
        &:not(.@{columns--prefix-cls}--vertical) {
            margin-inline-start: -@column--gap-xs;
        }
        &:not(.@{columns--prefix-cls}--vertical) > .@{column--prefix-cls} {
            padding-inline-start: @column--gap-xs;
        }
        &.@{columns--prefix-cls}--vertical > .@{column--prefix-cls}:not(:first-child) {
            padding-block-start: @column--gap-xs;
        }
    }

    &--gap-sm {
        &:not(.@{columns--prefix-cls}--vertical) {
            margin-inline-start: -@column--gap-sm;
        }
        &:not(.@{columns--prefix-cls}--vertical) > .@{column--prefix-cls} {
            padding-inline-start: @column--gap-sm;
        }
        &.@{columns--prefix-cls}--vertical > .@{column--prefix-cls}:not(:first-child) {
            padding-block-start: @column--gap-sm;
        }
    }

    &--gap-md {
        &:not(.@{columns--prefix-cls}--vertical) {
            margin-inline-start: -@column--gap-md;
        }
        &:not(.@{columns--prefix-cls}--vertical) > .@{column--prefix-cls} {
            padding-inline-start: @column--gap-md;
        }
        &.@{columns--prefix-cls}--vertical > .@{column--prefix-cls}:not(:first-child) {
            padding-block-start: @column--gap-md;
        }
    }

    &--gap-lg {
        &:not(.@{columns--prefix-cls}--vertical) {
            margin-inline-start: -@column--gap-lg;
        }
        &:not(.@{columns--prefix-cls}--vertical) > .@{column--prefix-cls} {
            padding-inline-start: @column--gap-lg;
        }
        &.@{columns--prefix-cls}--vertical > .@{column--prefix-cls}:not(:first-child) {
            padding-block-start: @column--gap-lg;
        }
    }

    &--gap-xl {
        &:not(.@{columns--prefix-cls}--vertical) {
            margin-inline-start: -@column--gap-xl;
        }
        &:not(.@{columns--prefix-cls}--vertical) > .@{column--prefix-cls} {
            padding-inline-start: @column--gap-xl;
        }
        &.@{columns--prefix-cls}--vertical > .@{column--prefix-cls}:not(:first-child) {
            padding-block-start: @column--gap-xl;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;