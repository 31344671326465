// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "variables";

.@{radio--prefix-cls} {
    display: inline-block;
    position: relative;
    padding-inline-start: 20px;
    min-block-size: 20px;
    line-height: 20px;
    vertical-align: top;
    cursor: pointer;
    transition: opacity linear 0.2s;

    input[type="radio"]&__input {
        position: absolute;
        inline-size: 1px;
        block-size: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
    }

    &__indicator {
        position: absolute;
        box-sizing: border-box;
        margin-inline-start: -20px;
        inline-size: 20px;
        block-size: 20px;
        overflow: hidden;
        border: 1px solid #979797;
        border-radius: 50%;
        background-color: #fff;
        transition: border-color linear 0.2s, background-color linear 0.2s, box-shadow linear 0.2s;
    }

    &__text {
        display: inline-block;
        margin-inline-start: 8px;
        white-space: normal;
        vertical-align: top;
    }

    &:hover &__indicator {
        border-color: #28aade;
    }

    &__input:checked + &__indicator {
        border-color: #28aade;
        background-color: #28aade;

        &::before {
            content: "";
            display: block;
            inline-size: 8px;
            block-size: 8px;
            margin: 5px;
            border-radius: 50%;
            background-color: #fff;
        }
    }

    &__input:focus + &__indicator {
        border-color: #28aade;
        box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.24);
        outline: none;
    }

    &__input:disabled + &__indicator {
        border-color: #c1c1c1;
        background-color: #ececec;
    }

    &__input:checked:disabled + &__indicator::before {
        background-color: #979797;
    }

    &--disabled {
        opacity: 0.47;
        cursor: not-allowed;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;