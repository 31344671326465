// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "variables";

.@{overlay--prefix-cls} {
    inset-block-start: 0;
    inset-inline-start: 0;
    inline-size: 100%;
    block-size: 100%;
    display: flex;
    position: fixed;

    &__content {
        position: relative;
        overflow: auto;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        max-inline-size: var(--width, none);
    }

    &__content,
    &__body {
        display: flex;
        outline: none;
    }

    &__body {
        flex-direction: column;
        flex-grow: 1;
        max-block-size: 100%;
        min-block-size: 0;
    }

    &__side {
        display: none;
        align-items: var(--sideBannerAlign, center);
        flex: 0 0 var(--sideBannerWidth, 340px);
        background: var(--sideBannerBackground, #f3f4f5);
    }

    &__side-image,
    &__side > img,
    &__side > svg {
        margin: 0 auto;
        max-inline-size: var(--sideBannerWidth, 340px);
        max-block-size: 100%;
    }

    &--left {
        flex: 1 0 auto;
        flex-direction: row;
    }

    &--left &__content {
        border-start-end-radius: 16px;
        border-end-end-radius: 16px;
        box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
        transform: translate(-100%);
    }

    &--left &__content&--enter {
        transform: translate(-100%);
    }

    &--left &__content&--enter-active {
        transform: translate(0);
        transition: transform 300ms ease-in-out;
    }

    &--left &__content&--enter-done,
    &--left &__content&--exit {
        transform: translate(0);
    }

    &--left &__content&--exit-active {
        transform: translate(-100%);
        transition: transform 300ms ease-in-out;
    }

    &--right {
        flex: 1 0 auto;
        flex-direction: row-reverse;
    }

    &--right &__content {
        border-start-start-radius: 16px;
        border-end-start-radius: 16px;
        box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
        transform: translate(100%);
    }

    &--right &__content&--enter {
        transform: translate(100%);
    }

    &--right &__content&--enter-active {
        transform: translate(0);
        transition: transform 300ms ease-in-out;
    }

    &--right &__content&--enter-done,
    &--right &__content&--exit {
        transform: translate(0);
    }

    &--right &__content&--exit-active {
        transform: translate(100%);
        transition: transform 300ms ease-in-out;
    }

    &--center {
        justify-content: center;
        align-items: center;
    }

    &--center &__content {
        max-block-size: 90%;
        margin: logical 0 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        opacity: 0;

        @media (max-width: @screen-xs) {
            margin-inline: 16px;
            max-block-size: none;
        }
    }

    &--center &__content&--enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    &--center &__content&--enter-done,
    &--center &__content&--exit {
        opacity: 1;
    }

    &--center &__content&--exit-active {
        opacity: 0;
        transition: opacity 300ms ease-in;
    }

    &--xs &__content {
        flex-basis: 510px;
    }

    &--sm &__content {
        flex-basis: 640px;
    }

    &--md &__content {
        flex-basis: 900px;
    }

    &--lg &__content {
        flex-basis: 1200px;
    }

    @media (min-width: 850px) {
        &--side&--xs &__content {
            flex-basis: 850px;
        }

        &--side&--xs &__side {
            display: flex;
        }
    }

    @media (max-width: 850px) {
        &--side&--xs &__side {
            display: none;
        }
    }

    @media (min-width: 1000px) {
        &--side&--sm &__content {
            flex-basis: 1000px;
        }

        &--side&--sm &__side {
            display: flex;
        }
    }

    @media (min-width: 1240px) {
        &--side&--md &__content {
            flex-basis: 1240px;
        }

        &--side&--md &__side {
            display: flex;
        }
    }

    @media (min-width: 1540px) {
        &--side&--lg &__content {
            flex-basis: 1540px;
        }

        &--side&--lg &__side {
            display: flex;
        }
    }

    &__backdrop {
        position: fixed;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        opacity: 0;
        background-color: rgba(34, 34, 34, 0.7);
        overflow: auto;
        user-select: none;
        transition: opacity 300ms linear;
    }

    &__backdrop&--enter-active,
    &__backdrop&--enter-done {
        opacity: 1;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;