// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{select-multi-value--prefix-cls} {
    overflow: hidden;
    white-space: nowrap;
    user-select: none;

    &__content {
        display: flex;
        align-items: center;
        min-inline-size: 0;
        min-block-size: 16px;
        box-sizing: border-box;
        margin-block: 2px;
        margin-inline: 0 4px;
        line-height: 16px;
        border-radius: 2px;
        background-color: @select--multi-value-background-color;
    }

    &__label {
        padding-block: 0;
        padding-inline: 4px;
        font-size: 12px;
        box-sizing: border-box;
        border-radius: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        + .@{select-multi-value--prefix-cls}__remove {
            margin-inline-start: -4px;
        }
    }

    &__remove {
        flex: none;
        padding-block: 0;
        padding-inline: 1px;
        vertical-align: top;
        color: @select--multi-value-remove-color;
        cursor: pointer;

        &:hover {
            color: @select--multi-value-remove-color-hover;
        }

        .@{select-control--prefix-cls}--disabled &:hover {
            color: @select--multi-value-remove-color;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;