// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "variables";

/*
Tabs

Markup:
<span class="label">Default</span>

Modifiers:
.label--inactive
.label--info
.label--success
.label--warning
.label--danger
*/

.label-variant(@color) {
    background-color: @color;

    &[href] {
        &:hover,
        &:focus {
            background-color: darken(@color, 10%);
        }
    }
}

.@{label--prefix-cls} {
    display: inline-block;
    padding-block: @label-padding-vertical;
    padding-inline: @label-padding-horizontal;
    border-radius: @label-border-radius;
    font-size: @label-font-size;
    font-weight: @label-font-weight;
    line-height: @label-line-height;
    color: @label-color;
    text-align: center;
    vertical-align: top;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    .label-variant(@label-background);

    a& {
        &:hover,
        &:focus {
            color: @label-link-hover-color;
            text-decoration: none;
            cursor: pointer;
        }
    }

    &--inactive {
        color: @base-body-color;
        .label-variant(@label-inactive-background);
    }

    &--success {
        .label-variant(@label-success-background);
    }

    &--info {
        .label-variant(@label-info-background);
    }

    &--warning {
        .label-variant(@label-warning-background);
    }

    &--danger {
        .label-variant(@label-danger-background);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;