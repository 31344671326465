// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "variables";

.@{pagination--prefix-cls} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-block-end: -16px;
    margin-inline-start: -30px;

    &__options {
        display: flex;
        align-items: baseline;
    }

    &__options,
    &__pages {
        margin-block-end: 16px;
        margin-inline-start: 30px;
        white-space: nowrap;
    }

    &__control {
        margin-inline-start: 8px;
    }

    &__text {
        font-size: @pagination--font-size;
    }

    &__loader {
        display: inline-flex;
    }

    &__input {
        margin-inline-end: 4px;
    }

    &__pages &__text {
        color: @pagination--color-1;
    }

    &__popover {
        max-inline-size: 100%;
    }

    &__popover & {
        flex-wrap: nowrap;
        padding-block: 12px 8px;
        padding-inline: 12px;
    }

    &__popover &__options,
    &__popover &__pages {
        margin-block-end: 0;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;