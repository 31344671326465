// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@status-message--prefix-cls: ~"@{cls-prefix}status-message";

.@{status-message--prefix-cls} {
    padding-inline-start: 32px;

    &__icon {
        float: inline-start;
        margin-block-start: 2px;
        margin-inline-start: -22px;
    }

    &--info &__icon {
        color: @text-info-color;
    }
    &--success &__icon {
        color: @text-success-color;
    }
    &--warning &__icon {
        color: @text-warning-color;
    }
    &--danger &__icon {
        color: @text-danger-color;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;