// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@preview-panel--prefix-cls: ~"@{cls-prefix}preview-panel";

.@{preview-panel--prefix-cls} {
    position: relative;
    inline-size: 100%;
    border: 1px solid #ccc;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    min-block-size: 32px;

    &__image {
        max-inline-size: 100%;
        min-inline-size: 124px;
        block-size: auto;
        max-block-size: 100%;
        margin: auto;
        object-fit: cover;
    }

    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
    }

    &__content {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        display: flex;
        padding: 20px;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        transition: 0.3s;
        opacity: 1;

        &--empty {
            background-color: rgba(0, 0, 0, 0);
        }
    }

    &--background {
        background-repeat: no-repeat;
        background-position: center;
        min-inline-size: 124px;
        min-block-size: 200px;
    }

    &:not(:hover) > &__content {
        visibility: hidden;
        opacity: 0;
    }

    &--success,
    &--failure {
        background-color: #fff;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;