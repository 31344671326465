// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

// Open Sans
// sans-serif
// 7 charsets: latin, latin-ext, cyrillic, cyrillic-ext, greek, greek-ext, vietnamese
// woff2 - Chrome 26+, Opera 23+, Firefox 39+
// woff  - Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
// https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=greek,greek-ext,latin,cyrillic,latin-ext,vietnamese,cyrillic-ext
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Open Sans Light"), local("OpenSans-Light"),
        url("../fonts/open-sans-300.woff2") format("woff2"),
        url("../fonts/open-sans-300.woff") format("woff");
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Open Sans"), local("OpenSans"),
        url("../fonts/open-sans-regular.woff2") format("woff2"),
        url("../fonts/open-sans-regular.woff") format("woff");
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: local("Open Sans Semibold"), local("OpenSans-Semibold"),
        url("../fonts/open-sans-600.woff2") format("woff2"),
        url("../fonts/open-sans-600.woff") format("woff");
}

// Fix for tilde in 13px font-size
@font-face {
    font-family: "Open Sans Tilde";
    src: local("Arial");
    unicode-range: U+007E;
}
