// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Label/variables";
@import "../Button/variables";

.@{menu--prefix-cls} {
    position: relative;
    min-inline-size: 150px;
    max-inline-size: 100%;
    margin: 0;
    padding: logical 4px 0;
    list-style: none;
    font-size: @base-body-font-size;
    text-align: start;
    line-height: normal;
    background-color: @menu--background-color;
    border-radius: 2px;
    background-clip: padding-box;
    box-sizing: border-box;
    box-shadow: @token-shadow-5;

    &__base-item {
        display: flex;
        padding: logical 8px 16px;
        font-weight: 400;
        line-height: 20px;
        color: @menu--color;
        white-space: nowrap;

        .@{label--prefix-cls} {
            box-sizing: border-box;
            min-inline-size: 16px;
            margin-block-start: 2px;
            margin-inline-start: 8px;
            padding-block: 0;
            padding-inline: 4px;
            vertical-align: top;
            border-radius: 8px;
        }
    }

    &__header {
        padding: logical 12px 16px 4px;
        font-size: 10px;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: @menu--header-color;
    }

    &__item {
        cursor: pointer;
        outline: 0;

        &:hover {
            text-decoration: none;
            outline: 0;
            color: @menu--color-hover;
            background-color: @menu--background-color-hover;
        }

        &:focus,
        &--active {
            color: @menu--active-color-hover;
            background-color: @menu--active-background-color-hover;
        }

        &--disabled {
            &,
            &:hover,
            &:focus {
                color: @menu--color;
                opacity: 0.47;
                background-color: transparent;
                cursor: not-allowed;
            }
        }
    }

    &__base-item-icon,
    &__item--active &__base-item-icon,
    &__item:hover &__base-item-icon,
    &__item:focus &__base-item-icon {
        color: @button--icon-color;
    }

    &__base-item-content {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__divider {
        block-size: 1px;
        margin: logical 4px 0;
        background-color: @menu--divider-color;
    }

    &--icons &__base-item-icon {
        min-inline-size: 24px;
    }

    &--overlay {
        max-block-size: calc(100vh - 106px);
        border-radius: 0;
        overflow-x: auto;
    }

    &--overlay &__base-item {
        padding: 16px;
    }

    &--overlay&--icons &__base-item-icon,
    &--overlay&--icons + &--overlay &__base-item-icon {
        min-inline-size: 26px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;