// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Tabs/variables";
@import "../Heading/variables";
@import "../Layout/variables";

.@{page-header--prefix-cls} {
    margin-block-end: 16px;
    background: @page-header--background-color;

    &__inner {
        padding-block: 16px 0;
        padding-inline: 16px;
        inline-size: 100%;
        margin: auto;
        box-sizing: border-box;

        @media (min-width: @screen-sm) {
            padding-inline-end: 24px;
            padding-inline-start: 24px;
        }

        .@{layout--prefix-cls}--sm & {
            max-inline-size: 640px;
        }
        .@{layout--prefix-cls}--md & {
            max-inline-size: 960px;
        }
        .@{layout--prefix-cls}--lg & {
            max-inline-size: 1200px;
        }
    }

    &__top-addon {
        margin-block-end: 16px;
    }

    &__breadcrumbs {
        margin-block-start: -8px;
        padding-block-end: 4px;
    }

    &__title {
        display: flex;
        align-items: flex-start;
        padding-block-end: 16px;

        .@{heading--prefix-cls} {
            margin: 0;
        }
    }

    &__title-left-addon,
    &__title-right-addon {
        flex: none;
        display: flex;
        align-items: center;
        block-size: @page-header--title-addon-height;
    }

    &__title-left-addon {
        margin-inline-end: 8px;
    }

    &__title-right-addon {
        margin-inline-start: 8px;
    }

    &__content {
        padding-block-end: 16px;

        > :last-child {
            margin-block-end: 0;
        }
    }

    &__tabs {
        box-shadow: inset 0 -2px 0 @tabs--border-color;
    }

    &__tabs-inner {
        padding-inline-end: 16px;
        padding-inline-start: 16px;
        inline-size: 100%;
        margin: auto;
        box-sizing: border-box;

        @media (min-width: @screen-sm) {
            padding-inline-end: 24px;
            padding-inline-start: 24px;
        }

        .@{layout--prefix-cls}--sm & {
            max-inline-size: 640px;
        }
        .@{layout--prefix-cls}--md & {
            max-inline-size: 960px;
        }
        .@{layout--prefix-cls}--lg & {
            max-inline-size: 1200px;
        }
    }

    &__tabs .@{tabs--prefix-cls}__nav {
        margin-block-end: 0;
    }

    &__tabs .@{tabs--prefix-cls}__tab-list {
        margin-block: 0;
        margin-inline: -16px;
        padding-block: 0;
        padding-inline: 16px;

        @media (min-width: @screen-sm) {
            margin-block: 0;
            margin-inline: -24px;
            padding-block: 0;
            padding-inline: 24px;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;