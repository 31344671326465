// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../../helpers/mixins";
@import "../Icon/variables";
@import "../Button/variables";
@import "../Heading/variables";

@panel--prefix-cls: ~"@{cls-prefix}panel";

@panel--color: @base-body-color;
@panel--background-color: #fff;
@panel--shadow: @token-shadow-4;
@panel--header-color: @base-body-color;
@panel--header-background-color: @token-color-grey-3;
@panel--header-border: @token-color-grey-3;
@panel--animation: ~"@{panel--prefix-cls}--fade";

.@{panel--prefix-cls} {
    margin-block-end: 16px;
    border-radius: 4px;
    background-color: @panel--background-color;
    box-shadow: @panel--shadow;

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        min-block-size: 28px;
        padding-block: 6px;
        padding-inline: 16px;
        border-block-end: 1px solid @panel--header-border;
        border-start-start-radius: 4px;
        border-start-end-radius: 4px;
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        background-color: @panel--header-background-color;

        .@{heading--prefix-cls} {
            flex: 1;
            margin: 0;
        }
    }

    &--collapsible &__header {
        cursor: pointer;
    }

    &--collapsed &__header {
        border-radius: 4px;
    }

    &__title {
        flex: 1;
        margin: 0;
        line-height: 20px;
        font-weight: 600;
        color: @panel--header-color;
    }

    &__extra {
        display: flex;
        align-self: flex-start;
        align-items: center;
        margin-inline-end: -8px;

        > a:not(.@{button--prefix-cls}--ghost) {
            margin-block: 0;
            margin-inline: 8px;
            font-size: 12px;
        }
    }

    &__control-button {
        .@{icon--prefix-cls} {
            transition: transform 0.15s;
        }
        .@{icon--prefix-cls}--flip-vertical {
            transform: rotate(180deg);
        }
    }

    &__content {
        padding: logical 12px 16px;
        word-wrap: break-word;
        color: @panel--color;
        animation: @panel--animation 0.3s ease-in-out;
    }

    &--collapsed &__content {
        display: none;
    }
}

@keyframes @panel--animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;