// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

/*
Carousel

Markup:
<div class="carousel">
  <div class="carousel__slides">
    {{children}}
  </div>
  <div class="carousel__controls">
    <button class="carousel__control carousel__control--previous">
        <svg viewBox="0 0 100 100">
            <path d="M 50,0 L 60,10 L 20,50 L 60,90 L 50,100 L 0,50 Z" class="arrow" transform="translate(15,0)"></path>
        </svg>
    </button>
    <button class="carousel__control carousel__control--next">
        <svg viewBox="0 0 100 100">
            <path d="M 50,0 L 60,10 L 20,50 L 60,90 L 50,100 L 0,50 Z" class="arrow" transform="translate(85,100) rotate(180)"></path>
        </svg>
    </button>
  </div>
</div>
*/

@carousel--prefix-cls: ~"@{cls-prefix}carousel";

@carousel--control-color: #fff;
@carousel--control-background-color: rgba(0, 0, 0, 0.3);

.@{carousel--prefix-cls} {
    position: relative;
    overflow: hidden;
    margin-block-end: 16px;
    text-align: center;

    &__slides,
    &__genuine-slides {
        display: inline-flex;
    }

    &__control {
        position: absolute;
        inset-block-start: 0;
        block-size: 100%;
        margin: 0;
        padding-block: 0;
        padding-inline: 3px;
        border: none;
        background-color: @carousel--control-background-color;
        color: @carousel--control-color;
        opacity: 0.3;
        cursor: pointer;

        &--previous {
            inset-inline-start: 0;
        }

        &--next {
            inset-inline-end: 0;
        }

        &:hover {
            opacity: 1;
        }

        &:focus {
            outline: none;
        }
    }

    &__control-icon {
        block-size: 20px;
        inline-size: 20px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;